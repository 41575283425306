body {
  margin: 0;
  font-family: -apple-system, Arial, Helvetica, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

body::-webkit-scrollbar{
  width: 7px;
}

body::-webkit-scrollbar-thumb{
  background: #008800;
  border-radius: 5px;
}

.a-header{
  color: #ffff;
  font-size: 1.15rem;
}

.a-header-contactanos{
  background-color: rgba(5,170,238, 1);
  border-radius: 20px;
}

.a-header-contactanos:hover{
  text-decoration: none;
  background-color: #B7E2F3 !important;
  color: #404040 !important;
}

.nav-link.a-header:hover, .nav-link.a-header:focus{
  color: #ffff;
}

.navbar-collapse-header{
  flex-grow: inherit;
}

.a-view-more{
  background-color: #008800;
  border-radius: 20px;
}

.a-view-more:hover{
  text-decoration: none;
  background-color: #93e889 !important;
  color: #404040 !important;
}

.a-view-more-prods{
  width: 18rem;
  text-align: center;
  padding: 0.5rem;
}

.section-container{
  width: 100%;
}

.section-productos,.section-contactanos, .section-data, .section-nosotros, .sub-nosotros{
  display: flex !important;
  align-items: center !important;
  justify-content: center !important;
  flex-direction: column !important;
  width: 100%;
}

.section-data{
  color: #ffff;
  height: 70%;
}

.sub-nosotros{
  height: 100%;
}

.section-nosotros{
  background-color: #008800;
  color: #ffff;
  height: 25rem;
}

.video-init{
  border-radius: revert;
}

.contSubtitle .subtitle{
  font-size: 34px;
}

.subtitle{
  font-size: 24px;
}

.title{
  font-size: 75px;
}

.btn-cotizacion{
  color: #ffff;
  text-decoration: none;
  padding: 0.4rem;
  background-color: rgba(5,170,238, 1);
  border-radius: 20px;
  font-size: 14px;
}

.btn-cotizacion:hover{
  background-color: #B7E2F3 !important;
  color: #404040 !important;
}

.logo-whatsapp,.logo-whatsapp2{
  position: fixed;
  bottom: 70px;
  right: 5px;
  background-color: rgb(37,211,102);
  color: #ffff;
  border-radius: 100%;
  
}

.logo-whatsapp2{
  right: 80px;
}

.footer-info a, .footer-social a, .footer-copy a{
  text-decoration: none;
  color: inherit;
}

.footer-copy{
  font-size: 0.7rem;
}

.card.card-prods{
  width: 18rem;
}
.card-body.card-body-prods{
  padding: 0;
  height: 518px !important;
}
.card-header.card-header-prods,.card-footer.card-footer-prods{
  text-align: center;
  vertical-align: middle;
}

.contSubtitle{
  text-shadow: 12px 12px 12px rgba(0, 0, 0, 0.45);
}
.text-decoration{
  text-shadow: 6px 6px 12px #008800;
}
.container-overlay-data{
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
}

.mr-top-negative{
  margin-top: -25px;
}

#Inicio{
  border: none;
}

@media (max-width: 450px){
  .img-logo{
    width: 120px;
    height: 44px;
  }
}

@media (max-width: 800px){
  .header-top{
    padding-bottom: 0;
    padding-top: 0px;
    margin-bottom: 0px;
  }
  .section-data{
    margin-top: -15px;
  }

  .mr-top-negative{
    margin-top: -5px;
  }
  .card-img-overlay{
    padding-top: 0;
    padding-left: 1px;
  }
  .title{
    font-size: 25px;
  }
  .contSubtitle .subtitle, .subtitle{
    font-size: 14px;
  }
}

@media (max-width: 1025px){
  .footer-info{
    flex-wrap: wrap;
    justify-content: center;
  }
  .footer-social{
    display: flex;
    justify-content: center !important;
    margin-top: 10px;
    margin-bottom: 10px;
  }
  .footer-copy{
    flex-direction: column;
    gap: 0 !important;
    justify-content: center !important;
    align-items: center;
  }
  .separator{
    display: none;
  }
  .navbar-collapse-header, .navbar-toggler{
    display: none;
  }
}